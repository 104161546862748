import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { BlogPostHeader, BlogSliceZone, RelatedPosts } from 'components/Blog';
import { DarkDividerSlice } from 'components/Slices';

const BlogPost = ({ data, location, ...props }) => {
  const {
    data: {
      meta_title,
      meta_image,
      meta_description,
      title,
      subtitle,
      featured_image,
      body,
      related_posts,
    },
    lang: pageLang,
    alternate_languages: altLangs,
    first_publication_date,
  } = data.post.edges[0].node;

  const {
    data: { body: bodyBlog },
  } = data.blog.edges[0].node;
  return (
    <Layout
      pageLang={pageLang}
      altLangs={altLangs}
      location={location}
      seo={{
        title: meta_title || title.text,
        description: meta_description || subtitle.text,
        image: meta_image?.url || featured_image.url,
      }}
      whiteHeader={true}
    >
      <BlogPostHeader
        publishedAt={first_publication_date}
        title={title}
        subtitle={subtitle}
        image={featured_image}
        pageLang={pageLang}
      />
      <BlogSliceZone allSlices={body} />
      {related_posts?.length != 0 && (
        <RelatedPosts posts={related_posts} pageLang={pageLang} />
      )}
      {bodyBlog.map(({ id, primary, slice_type }) => {
        if (slice_type === 'dark_divider') {
          return <DarkDividerSlice key={id} data={primary} />;
        }
      })}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostQuery($id: String!, $locale: String!) {
    blog: allPrismicBlog(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              ... on PrismicBlogBodyDarkDivider {
                id
                primary {
                  headline_post_highlight {
                    text
                  }
                  headline_highlight {
                    text
                  }
                  headline_pre_highlight {
                    text
                  }
                  text {
                    text
                    html
                  }
                  button_text {
                    text
                  }
                  button_text_demo {
                    text
                  }
                  button_text_secondary {
                    text
                  }
                  navigation_link {
                    url
                  }
                  navigation_link_secondary {
                    url
                  }
                  image_avatar {
                    url
                    alt
                  }
                  avatar_name {
                    text
                  }
                  avatar_title {
                    text
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
    post: allPrismicBlogPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          lang
          alternate_languages {
            lang
            uid
          }
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            title {
              text
            }
            subtitle {
              text
            }
            featured_image {
              url
            }
            related_posts {
              blog_post {
                document {
                  ... on PrismicBlogPost {
                    id
                    uid
                    url
                    first_publication_date
                    data {
                      title {
                        text
                      }
                      subtitle {
                        text
                      }
                      featured_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicBlogPostBodyText {
                id
                slice_type
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicBlogPostBodyVideo {
                id
                slice_type
                primary {
                  video {
                    embed_url
                  }
                }
              }
              ... on PrismicBlogPostBodyImage {
                id
                slice_type
                primary {
                  image {
                    url
                  }
                }
              }
              ... on PrismicBlogPostBodyImageText {
                id
                slice_type
                primary {
                  left_to_right
                  image {
                    alt
                    url
                  }
                  image_subtitle {
                    text
                  }
                  text {
                    html
                  }
                }
              }
              ... on PrismicBlogPostBodyTwoImages {
                id
                slice_type
                primary {
                  image_two {
                    alt
                    url
                  }
                  image_one {
                    alt
                    url
                  }
                }
              }
              ... on PrismicBlogPostBodyDarkDivider {
                id
                primary {
                  headline {
                    text
                  }
                  text {
                    text
                    html
                  }
                  button_text {
                    text
                  }
                  navigation_link {
                    url
                  }
                }
                slice_type
              }
            }
          }
          uid
          first_publication_date
        }
      }
    }
  }
`;

export default BlogPost;
